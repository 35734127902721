import React, { useState, useEffect } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./atopic.module.css"
import { desc } from "./atopic.module.css"
import { thumNails } from "./atopic.module.css"
import { curThumnail } from "./atopic.module.css"
import { thumNail } from "./atopic.module.css"
import { thumNailUme } from "./atopic.module.css"
import { modalArea } from "./atopic.module.css"
import { isShowCss } from "./atopic.module.css"
import { modalBg } from "./atopic.module.css"
import { modalWrapperWrapper } from "./atopic.module.css"
import { modalWrapperScale } from "./atopic.module.css"
import { modalBack } from "./atopic.module.css"
import { modalWrapper } from "./atopic.module.css"
import { modalContentCSS } from "./atopic.module.css"
import { decription } from "./atopic.module.css"
import { close } from "./atopic.module.css"

import getLanguage from "../../utilt/getLangueage"
import calcModalSize from "../../utilt/calcModalSize"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//import seoImg from "../../images/seo/seo_atopic.jpg"


/**
 * ページ
 */
const Atopic = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query MyQueryAtopic {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "atopic"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
  allAtopicJson {
    edges {
      node {
        description
        title
      }
    }
  }
}
`)

  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const images = imagesOld.slice();
  //2 ファイル名でソート
  function compare(a, b) {
    const baseA = a.base;
    const baseB = b.base;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  images.sort(compare);
  for (let i = 0; i < images.length; i++) {
    images[i].index = i;
  }
  //3 配列を二分してサムネイルとモーダル表示用画像を分ける
  const ImagesLength = Math.floor(images.length / 2);
  const modalImages = images.slice(0, ImagesLength);
  const thumbsImages = images.slice(ImagesLength);
  //4 サムネイルにindexを付ける
  for (let i = 0; i < ImagesLength; i++) {
    modalImages[i].index = i;
    thumbsImages[i].index = i;
  }

  //5 JSONデータを配列化
  const descJsons = data.allAtopicJson.edges.map(n => n.node);
  //6 JSONもファイル名でソート
  function compareJason(a, b) {
    const baseA = a.title;
    const baseB = b.title;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  descJsons.sort(compareJason);
  for (let i = 0; i < descJsons.length; i++) {
    const desc = descJsons[i].description.slice(34).slice(0, -5).replace(/x/g, " × ").replace("(cm", " (cm");
    descJsons[i].index = i;
    descJsons[i].desc = desc;
  }



  /**
   * モダール用
   */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }


  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    } else {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }
  }

  //for touchscreen
  useEffect(() => {
    function plusPicNum() {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    }

    function minusPicNum() {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }

    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, ImagesLength])


  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % ImagesLength;
        setPicNum(result);
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
        setPicNum(result);
      } else if (event.keyCode === 27) {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      document.getElementById(`pic${String(picNum)}`).focus();
      //console.log(document.getElementById(`pic${String(picNum)}`));
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, isShow, ImagesLength])


  const modaleScale = 1.85;
  return <>
    <Seo
      title="atopic"
      description={lang === "en" ?
        "Drawings on paper clay" :
        "ジェッソを塗った紙粘土に水性インクでドローイング"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_atopic.jpg"
      pathname="/work/atopic"
    />

    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1 className={eachTitle}>
          {lang === "en" ? "atopic" : "atopic"}

        </h1>
        <p className={desc}>
          {lang === "en" ?
            `ink and gesso on paper clay, 2009` :
            `インク、ジェッソ、紙粘土、2009年`}
        </p>

        <div
          className={thumNails}
        >
          {thumbsImages.map((node) => (
            <div
              className={isShow && picNum === node.index ? curThumnail : thumNail}
              onClick={event => handleModal(event, node.index)}
              onKeyDown={event => handleModalKey(event, node.index)}
              role="button"
              tabIndex={0}
              style={{
              }}
              key={node.base.split(".")[0]}
              id={"pic" + String(node.index)}
            >
              <GatsbyImage
                image={node.childImageSharp.gatsbyImageData}
                title={descJsons[node.index].desc}
                alt={node.base.split(".")[0]} />
            </div>
          ))}
          <div className={`${thumNail} ${thumNailUme} `}> </div>
          <div className={`${thumNail} ${thumNailUme} `}> </div>

        </div>
      </Article>
    </Layout>


    {/**
       * モーダル
      */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCss}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >

        <div
          className={
            isShow ? `${modalWrapperWrapper} ${modalWrapperScale}` : modalWrapperWrapper
          }
          style={{
            width:
              calcModalSize(winSize, 1600 * 0.25,
                4 / 3, modaleScale, 0.9) + "px"
          }}
        >
          <div
            className={modalBack}
          >
            <div
              className={modalWrapper}
              onClick={event => clickModal(event)}
              onKeyDown={() => { }}
              role="button"
              tabIndex={0}
              id="modalContent"
            >
              <div
                className={modalContentCSS}
              >
                <GatsbyImage
                  image={modalImages[picNum].childImageSharp.gatsbyImageData}
                  alt={modalImages[picNum].base.split(".")[0]} />
              </div>
              <div
                className={decription}
              >
                {descJsons[picNum].desc}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>
  </>;
}

export default Atopic



